.market-spacing {
  margin-bottom: -5%;
}

@media (max-width: 768px) {
  .market-title-container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }

  .market-spacing {
    margin-bottom: 0 !important;
  }

  .market-title-container h2 {
    font-size: 28px;
    margin-bottom: 16px;
  }

  .market-title-container p {
    font-size: 16px;
    line-height: 1.5;
    padding: 0 16px;
    margin-bottom: 16px;
  }

  .market-tabs-container {
    margin-top: -40px;
  }
}
