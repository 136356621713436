/* Mobile styles for Exchange component */
@media screen and (max-width: 740px) {
  /* Card styles */
  .card {
    margin: 0;
    border-radius: 8px;
  }

  .card-body {
    padding: 16px !important;
  }

  /* Container adjustments */
  .container-xxl {
    padding: 0 12px;
  }

  .row.g-3 {
    margin: 0;
  }

  .col-md-12 {
    padding: 0;
  }

  /* Fix the positioning issues */
  .d-flex.justify-content-between.align-items-start {
    flex-direction: column;
  }

  .flex-grow-1 {
    width: 100%;
  }

  /* Reset absolute positioning for select container */
  .d-flex.justify-content-end.align-items-center.mb-3 {
    position: static !important;
    width: 100%;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  /* Basket selector styles */
  .form-select {
    width: 100% !important;
    margin-bottom: 8px;
  }

  /* Description section */
  .row {
    margin-top: 8px;
  }

  .col-md-6 {
    margin-bottom: 24px;
    padding: 0;
  }

  h6 {
    font-size: 1rem;
    margin-bottom: 12px !important;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  /* Details section */
  .table {
    margin-bottom: 0;
  }

  td {
    padding: 8px 0 !important;
  }

  /* Contract address section */
  .mb-0 {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  /* Hide info tooltip on mobile */
  .info-tooltip {
    display: none !important;
  }

  .weights-popup {
    display: none !important;
  }

  /* Price details */
  td p {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 0;
  }

  td:last-child p {
    border-bottom: none;
  }

  /* Trade History Mobile Styles */
  .table-responsive {
    margin: 0 -16px;
    padding: 0 16px;
    border: none;
  }

  .table-responsive .table {
    font-size: 0.9rem;
  }

  .table-responsive thead {
    display: none; /* Hide headers on mobile */
  }

  .table-responsive tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .table-responsive tbody tr:last-child {
    border-bottom: none;
  }

  .table-responsive tbody td {
    display: flex;
    flex-direction: column;
    padding: 4px 0 !important;
    border: none;
  }

  .table-responsive tbody td::before {
    content: attr(data-label);
    font-size: 0.75rem;
    color: #6c757d;
    margin-bottom: 4px;
  }

  /* Badge styling */
  .badge {
    display: inline-block;
    padding: 4px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 4px;
  }

  /* Card header */
  .card-header {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .card-title {
    font-size: 1.1rem;
    margin: 0;
  }

  /* Balance Details Mobile Styles */
  .container-fluid.p-4 {
    padding: 0 !important;
  }

  .balance-info {
    padding: 0 !important;
  }

  .balance-info .py-3 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .balance-info .table-responsive {
    margin: 0 -12px;
    padding: 0 12px;
    border: none;
  }

  .balance-info .table {
    font-size: 0.9rem;
  }

  .balance-info thead {
    display: none; /* Hide headers on mobile */
  }

  .balance-info tbody tr {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 8px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .balance-info tbody tr:last-child {
    border-bottom: none;
  }

  .balance-info tbody td {
    display: flex;
    align-items: center;
    padding: 4px 0 !important;
    border: none;
  }

  /* First column (Asset name) */
  .balance-info tbody td:first-child {
    font-weight: 500;
    grid-column: 1 / -1;
  }

  /* Amount and Value columns */
  .balance-info tbody td:nth-child(2),
  .balance-info tbody td:nth-child(3) {
    font-size: 0.9rem;
  }

  .balance-info tbody td:nth-child(2)::before {
    content: "Amount: ";
    color: #6c757d;
    font-size: 0.8rem;
    margin-right: 4px;
  }

  .balance-info tbody td:nth-child(3)::before {
    content: "Value: ";
    color: #6c757d;
    font-size: 0.8rem;
    margin-right: 4px;
  }

  /* Total row styling */
  .balance-info tbody tr.border-top {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    margin-top: 4px;
    padding: 8px;
  }

  .balance-info tbody tr.border-top td:first-child {
    font-weight: 600;
    color: #333;
  }

  .balance-info tbody tr.border-top td:last-child {
    font-weight: 600;
    color: #333;
    font-size: 1rem;
  }

  /* Chart container adjustments */
  .chart-container {
    height: 300px !important;
    margin-top: 24px;
  }

  /* Chart legend adjustments */
  .apexcharts-legend {
    padding: 0 !important;
    margin-top: 16px !important;
  }

  .apexcharts-legend-series {
    margin: 4px 0 !important;
  }

  /* Card adjustments */
  .card.border-0 .card-body {
    padding: 8px !important;
  }

  /* Text adjustments */
  .text-muted.mb-2 {
    font-size: 1rem;
    margin-bottom: 8px !important;
    font-weight: 500;
  }

  /* Transaction Mobile Styles - Updated to match Trade History */
  .rdt_Table {
    font-size: 0.9rem !important;
  }

  .rdt_TableHeader,
  .rdt_TableHead {
    display: none !important;
  }

  .rdt_TableBody {
    padding: 0 !important;
  }

  .rdt_TableRow {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 8px !important;
    padding: 12px 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    margin: 0 !important;
  }

  .rdt_TableRow:last-child {
    border-bottom: none !important;
  }

  .rdt_TableCell {
    display: flex !important;
    flex-direction: column !important;
    padding: 4px 0 !important;
    margin: 0 !important;
    border: none !important;
    min-height: unset !important;
    justify-content: flex-start !important;
  }

  /* Date styling */
  .rdt_TableCell:nth-child(1) {
    grid-column: 1 / -1 !important;
    font-size: 0.8rem !important;
    color: #6c757d !important;
    margin-bottom: 4px !important;
  }

  /* Type styling */
  .rdt_TableCell:nth-child(2) {
    grid-column: 1 / 2 !important;
  }

  /* Amount styling */
  .rdt_TableCell:nth-child(3) {
    grid-column: 2 / 3 !important;
    font-weight: 500 !important;
  }

  /* Status styling */
  .rdt_TableCell:nth-child(4) {
    grid-column: 1 / 2 !important;
  }

  /* Transaction Hash styling */
  .rdt_TableCell:nth-child(5) {
    grid-column: 2 / 3 !important;
  }

  /* Add labels for each cell */
  .rdt_TableCell::before {
    content: attr(data-name);
    font-size: 0.75rem;
    color: #6c757d;
    margin-bottom: 4px;
  }

  /* Badge styling */
  .rdt_TableCell .badge {
    align-self: flex-start;
    margin-top: 4px;
  }

  /* Pagination adjustments */
  .rdt_Pagination {
    font-size: 0.9rem !important;
    padding: 16px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
    display: flex !important;
    justify-content: center !important;
  }

  .rdt_Pagination button {
    padding: 4px 8px !important;
    margin: 0 4px !important;
  }

  /* Container and spacing adjustments */
  .dataTables_wrapper {
    margin: 0 -12px;
    padding: 0 12px;
  }

  /* Container adjustments */
  .container-fluid.p-4 {
    padding: 12px !important;
  }

  .card-header.py-3 {
    padding: 16px !important;
  }

  .card-header h6 {
    font-size: 1.1rem !important;
    margin: 0 !important;
  }

  .card-body {
    padding: 0 12px 12px 12px !important;
  }

  /* Link styling */
  .text-primary {
    text-decoration: none !important;
  }

  /* Row spacing */
  .row {
    margin: 0 !important;
  }

  .col-sm-12 {
    padding: 0 !important;
  }

  /* Hide chart on mobile */
  .col-lg-6:last-child {
    display: none !important;
  }

  /* Ensure balance info takes full width */
  .col-lg-6:first-child {
    width: 100% !important;
  }

  /* Remove chart container styles since it's hidden */
  .chart-container {
    display: none !important;
  }

  /* Header title adjustments */
  .body-header {
    padding-left: 8px !important;
  }

  .body-header h4 {
    margin-left: 4px !important;
  }
}
