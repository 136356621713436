@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
  }

  .footer-logo {
    order: 1;
    justify-content: center !important;
  }

  .footer-links {
    order: 2;
    width: 100% !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 24px !important;
  }

  .footer-social {
    order: 3;
    justify-content: center !important;
    width: 100%;
  }

  .footer-social > div {
    justify-content: center;
  }

  .footer-link-text {
    font-size: 14px !important;
  }
}
